<script>
import { CountTo } from "vue3-count-to";
import Swal from "sweetalert2";
import axios from 'axios';
import serverConfig from '@/helpers/config';
import moment from 'moment'

export default {
  name:'Widget',
  setup() {
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk };
    },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      dateRange:{
        start_tmp: moment().unix(),
        end_tmp: moment().add(30,'days').unix(),
      },
      crmWidgets: [
        {
          id: 1,
          label: "Notifiche",
          badge: "ri-arrow-up-circle-line text-success",
          icon: "bx bx-bell",
          counter: "0",
          decimals: 0,
          suffix: "",
          prefix: "",
        },
        {
          id: 2,
          label: "Prossimi Eventi",
          badge: "ri-arrow-up-circle-line text-success",
          icon: "ri-calendar-2-line",
          counter: "0",
          decimals: 0,
          suffix: "",
          prefix: "",
        },
        {
          id: 3,
          label: "Attività in corso",
          badge: "ri-arrow-up-circle-line text-success",
          icon: "mdi mdi-clipboard-edit-outline",
          counter: "0",
          decimals: 1,
          suffix: "",
          prefix: "",
        },
        {
          id: 4,
          label: "Prossime Attività",
          badge: "ri-arrow-down-circle-line text-danger",
          icon: "ri-clipboard-line",
          counter: "0",
          decimals: 2,
          suffix: "",
          prefix: "",
        },
        {
          id: 5,
          label: "Attività scadute",
          badge: "ri-arrow-up-circle-line text-success",
          icon: "ri-file-warning-fill",
          counter: "0",
          decimals: 1,
          prefix: "",
          separator: ",",
          suffix: "",
        },
        {
          id: 6,
          label: "Commesse in produzione",
          badge: "ri-arrow-down-circle-line text-danger",
          icon: "ri-space-ship-line",
          counter: "0",
          decimals: 0,
          separator: ",",
          suffix: "",
          prefix: "",
        },
        {
          id: 7,
          label: "Preventivi/Ordini",
          badge: "ri-arrow-down-circle-line text-danger",
          icon: "mdi mdi-cart-variant",
          counter: "0",
          decimals: 0,
          separator: ",",
          suffix: "",
          prefix: "",
        },
      ],
    };
  },
  mounted(){
    this.getList()
  },
  methods:{
    axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/my-dashboard')               
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getList(){
       this.axiosInterceptor()
       axios.get(`${this.UrlServer}tasks/by-user/summary`, 
        { 
          params: { 
            start_tmp: this.dateRange.start_tmp,
            end_tmp: this.dateRange.end_tmp,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        if(response.data) {
          for (let item of this.crmWidgets){
            
            if (item.id==1) {
              item.counter =  response.data.notifications.toString()
            }
            if (item.id==2) {
              item.counter =  response.data.events.toString()
            }
            if (item.id==3) {
              item.counter =  response.data.taskInProgress.toString()
            }
            if (item.id==4) {
              item.counter =  response.data.upcomingTasks.toString()
            }
            if (item.id==5) {
              item.counter =  response.data.expiredTasks.toString()
            }
            if (item.id==6) {
              item.counter =  response.data.projects.toString()
            }
            if (item.id==7) {
              item.counter =  response.data.carts.toString()
            }
          }
          
        }
        
      })
      },
    checkValue(val){
      if(val) {
        return parseInt(val)
      }
    },

  },
  components: {
    CountTo
  }
};
</script>

<template>
  <div class="card crm-widget">
    <div class="card-body p-0">
      <div class="row  g-0">
        <div class="col " v-for="(item, index) of crmWidgets" :key="index">
          <div class="py-4 px-4 pr-2">
            
            <h5 class="text-muted text-uppercase fs-13">
              <strong>{{ item.label }}</strong>
              
                <template v-if="item.id==5 || item.id == 1">
                  <template v-if="checkValue(item.counter)>0">
                    <i class="ri-bug-fill text-danger fs-18 float-end align-middle"></i>
                  </template>
                  <template v-else>
                    <i class="ri-chat-smile-2-line text-success fs-18 float-end align-middle"></i>
                  </template>
                </template>
                <template v-else>
                  <template v-if="checkValue(item.counter)>0">
                    <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
                  </template>
                  <template v-else>
                    <i class="ri-arrow-down-circle-line fs-18 float-end align-middle"></i>
                  </template>
                </template>
            </h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <template v-if="item.id==5">
                  <template v-if="checkValue(item.counter)>0">
                    <i :class="`${item.icon} display-6 text-danger `"></i>
                  </template>
                  <template v-else>
                    <i :class="`${item.icon} display-6 text-muted `"></i>
                  </template>
                </template>
                <template v-else-if="item.id == 1">
                  <router-link to="/my-calendar">
                  <template v-if="checkValue(item.counter)>0">
                    <i :class="`${item.icon} display-6 text-danger `"></i>
                  </template>
                  <template v-else>
                    <i :class="`${item.icon} display-6 text-muted `"></i>
                  </template>
                  </router-link>
                </template>
                <template v-else-if="item.id == 2">
                  <router-link to="/my-calendar">
                  <template v-if="checkValue(item.counter)>0">
                    <i :class="`${item.icon} display-6 text-success `"></i>
                  </template>
                  <template v-else>
                    <i :class="`${item.icon} display-6 text-muted `"></i>
                  </template>
                  </router-link>
                </template>
                <template v-else-if="item.id==7">
                  <router-link to="/ecommerce/orders">
                  <template v-if="checkValue(item.counter)>0">
                    <i :class="`${item.icon} display-6 text-success `"></i>
                  </template>
                  <template v-else>
                    <i :class="`${item.icon} display-6 text-muted `"></i>
                  </template>
                  </router-link>
                </template>
                <template v-else>
                  <template v-if="checkValue(item.counter)>0">
                    <i :class="`${item.icon} display-6 text-success `"></i>
                  </template>
                  <template v-else>
                    <i :class="`${item.icon} display-6 text-muted `"></i>
                  </template>
                 
                </template>
                
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">
                  {{item.prefix}}<count-to :startVal='0' :endVal='item.counter' :duration='5000'></count-to>{{item.suffix}}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
