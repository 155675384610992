<script>
  import { mapActions } from "vuex";
  import "@vueform/multiselect/themes/default.css";

  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    name:'UpcomingActivity',
    page: {
      title: "Lista attività richieste",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Lista attività richieste",
        items: [{
            text: "Cantieri",
            href: "/support/list-view",
          },
          {
            text: "Lista",
            active: true,
          },
        ],
        date3: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        responsesList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        form:{
          start:moment().format('YYYY-MM-DD'),
          end:moment().format('YYYY-MM-DD'),
          status:'init',
          select:'',
        },
        n_results:0,
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        minDateEnd:moment().format('YYYY-MM-DD'),
        maxDateEnd:new Date().getFullYear().toString() + '-12-31',
        dateRange:{
            start_tmp: moment().unix(),
            start_str: moment().format('DD/MM/YYYY HH:MM'),
            end_str: moment().add(10,'days').format('DD/MM/YYYY HH:MM'),
            end_tmp:moment().add(10,'days').unix(),
        }
      };
    },
    components: {
      //lottie: Lottie,
  
     
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      this.getList()
    },

    methods: {
      convertDate(date){
        if (date) {
          moment.locale('it')
          return moment.unix(date).format('DD/MM/YYYY')
        }
      },
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
    
        axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/my-dashboard')              
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getList(){
       axios.get(`${this.UrlServer}tasks/per-page/by-user/to-start`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
            start_tmp: this.dateRange.start_tmp,
            end_tmp: this.dateRange.end_tmp,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{

        this.responsesList = response.data.results.data;
        this.paginated = response.data.results
        this.n_results = response.data.n_results
      })
      },
     
      editdata(data) {
        this.$router.push('/support/detail/'+data)
      },

      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('exampleModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },

      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    }
    },
    mounted() {

    }
  };
</script>

<template>    
<!-- Modulo tabella elenco progetti/attività richieste -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <template v-if="responsesList.length>0">
                <h3 class="card-title mb-0 flex-grow-1">
                  <b-alert show variant="success">
                    Prossime attività da compiere (dal <strong> {{ dateRange.start_str }}</strong>  al <strong>{{ dateRange.end_str }}</strong>)
                  </b-alert>
                </h3>
              </template>

              <div class="flex-shrink-0">
               
              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <template v-if="responsesList.length>0">
              <div class="table-card mt-50">          
                <div class="row thRow mt-50">
                    <div class="col-lg-2 col-md-12">
                        Nome
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Tipologia
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Data inizio
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Data fine
                    </div>
                    <div class="col-lg-2 col-md-12">
                        Stato
                    </div>
                    <div class="col-lg-2 col-md-12 text-right">
                        Visualizza
                    </div>
                </div>
              <div class="row list tRow form-check-all d-flex" v-for="(item, index) of responsesList" :key="index">
                <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                       
                          <template v-if="item.task_type == 'task-cantiere'">
                            <img src="@/assets/images/imp-cantiere-sm.png" alt="Pianificazione Giornaliera" title="Pianificazione Giornaliera" height="22" />
                          </template>
                          <template v-if="item.task_type == 'task-assistenza'">
                            <i class="mdi mdi-account-tie-voice" title="Servizio di Assistenza"></i>
                            
                          </template>
 
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ convertDatetime(item.startStr) }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <div class="list-inline hstack gap-2 mb-0">    
                          {{ convertDatetime(item.endStr) }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                      <template v-if="item.status=='completed'">
                        <span class="badge text-uppercase badge-soft-success">Completato</span>
                      </template>
                      <template v-if="item.status=='working'">
                        <span class="badge text-uppercase badge-soft-warning">In lavorazione</span>
                      </template>
                      <template v-if="item.status=='blocked'">
                        <span class="badge text-uppercase badge-soft-danger">Bloccato</span>
                      </template>
                      <template v-else>
                        <span class="badge text-uppercase badge-soft-info">Non avviato</span>
                      </template>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <ul class="list-inline text-left">
                          <template v-if="item.task_type=='task-cantiere' && $route.name=='worksite-detail'">
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                          </template>
                          <template v-if="item.task_type=='task-assistenza' && $route.name=='support-detail'">
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                          </template>

                            <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                              <template v-if="item.task_type=='task-cantiere'">
                                <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/tasks/detail/'+item._id)">
                                  <i class="ri-pencil-fill fs-16"></i>
                                </a>
                              </template>
                              <template v-if="item.task_type=='task-assistenza'">
                                <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/tasks/detail/'+item._id)">
                                  <i class="ri-pencil-fill fs-16"></i>
                                </a>
                              </template>
                            </li>
                        </ul>
                    </div>
              </div>
              
            </div>
            </template>
            <template v-else> 
              <h3><strong>Nessuna attività in programma</strong></h3>
            </template>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

<!-- / Modulo tabella elenco progetti/attività richieste -->      
</template>