<script>
import { mapActions } from "vuex";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import Widget from "./widget.vue";
//import SalesForecast from "./forecast.vue";
import UpcomingActivity from "./activities.vue";
import ActivitiesInProgress from "./activitiesInProgress.vue";
import ActivitiesExpired from "./activitiesExpired.vue";
import MyTableProjectsDashboard from "@/components/widgets/projects/MyTableProjectsDashboard";


import Swal from "sweetalert2";
import axios from 'axios';
import serverConfig from '@/helpers/config';

export default {
  setup() {
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk };
  },
  page: {
    title: "La mia bacheca",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      title: "La mia bacheca",
      items: [
        {
          text: "Area personale",
          href: "/my-dashboard",
        },
        {
          text: "La mia bacheca",
          active: true,
        },
      ],
    };
  },
  beforeMount() {
      axios.interceptors.response.use(
        (response) => {
            
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
            Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
             
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
      axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },

      ).then(response => {
          if (response.data){

            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
              }
            }
          }
      });
    },
    methods:{
      ...mapActions('layout', ['changeLayoutType', ]),
    },
  components: {
    Layout,
    PageHeader,
    Widget,
    ActivitiesInProgress,
    ActivitiesExpired,
    MyTableProjectsDashboard,
    UpcomingActivity,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-xl-12">
        <Widget />
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <ActivitiesInProgress /> 
      </div>
      <div class="col-lg-6 col-md-12">
        <UpcomingActivity />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12 mt-20">
        <ActivitiesExpired /> 
      </div>
      <div class="col-lg-6 col-md-12 mt-20">
        <MyTableProjectsDashboard />
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
